// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFUp8e9avBaB3rI8UlNusFkIjLQbqSEQQ",
  authDomain: "pro-home-workout-2fa01.firebaseapp.com",
  projectId: "pro-home-workout-2fa01",
  storageBucket: "pro-home-workout-2fa01.appspot.com",
  messagingSenderId: "889088509084",
  appId: "1:889088509084:web:85843669996cbcf6ef9959",
  measurementId: "G-QVS86QY6E3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };